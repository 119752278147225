:root {
  --color-primary: #3399ff;
  --color-primary-dark: #333399;
  --color-primary-light: #99cccc;
  --color-primary-lighter: #b5defc;
  --color-primary-lightest: #f0f7fd;
  --color-secondary: #ff9933;
  --color-secondary-dark: #ff6666;
  --color-facebook: #4267B2;
  --color-linkedin: #0a66c2;

  --font-size-5xl: 48px;
  --font-size-4xl: 36px;
  --font-size-3xl: 30px;
  --font-size-2xl: 24px;
  --font-size-xl: 20px;
  --font-size-lg: 18px;
  --font-size-md: 16px; 
  --font-size-sm: 14px;
  --font-size-xs: 12px;
}

@media screen and (max-width: 650px) {
  :root {
    --font-size-5xl: 48px;
    --font-size-4xl: 36px;
    --font-size-3xl: 30px;
    --font-size-2xl: 24px;
    --font-size-xl: 20px;
    --font-size-lg: 18px;
    --font-size-md: 16px; 
    --font-size-sm: 14px;
    --font-size-xs: 12px;
  }
}
/*----------------------------------------------------
	共通CSS
----------------------------------------------------*/
* {
  margin: 0;
  padding: 0;
  color: #000;
  text-decoration: none;
  box-sizing: border-box;
  font-size: 14px;
}
a,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
li,
dt,
dd,
th,
td {
  line-height: 1.4em;
}
ul,
ol {
  list-style: none;
}
table {
  border-collapse: collapse;
  width: 100%;
}

/* img  */
img {
  max-width: 100%;
  height: auto;
  width: auto;
  border: none;
}
img,
iframe {
  vertical-align: bottom;
}
@media screen and (max-width: 650px) {
  img {
    max-width: 100%;
    height: auto;
    width: auto;
  }
}

/* base */
html {
  font-family: 'Noto Sans JP', sans-serif;
  overflow-x: hidden;
}

html[lang|=ja] {
  font-family: 'Noto Sans JP', sans-serif;
}

html[lang|=zh] {
  font-family: 'Microsoft YaHei', 'Hiragino Sans GB', sans-serif;
}

body {
  min-width: 1150px; /*背景が消えるバグ防止*/
  background-color: #fff;
}
@media screen and (max-width: 650px) {
  body {
    /*mywidthが100%になるタイミングとセット*/
    min-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 54px;
  }
  body.fixed,
  html.fixed {
    overflow: hidden;
    height: 100%;
  }
  body#topB_pre {
    margin-top: 0;
  }
}

/*  width 管理  */
.mywidth {
  width: 1100px;
  margin: auto;
}
@media screen and (max-width: 650px) {
  .mywidth {
    width: 100%;
    margin: auto;
    padding: 0 10px;
  }
}

/*  header  */
#header {
  width: 100%;
}
.head-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.head-content .logo_wrap .text {
  color: #00429b;
  font-size: 13px;
  margin: 10px 0;
  line-height: 1em;
}
.head-content .sub_nav {
  display: flex;
}
.head-content .sub_nav a {
  height: 80px;
  font-size: 16ox;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
.head-content .sub_nav a.login {
  background: #3fa9f5;
  color: white;
  font-size: 18px;
  padding: 0 20px;
  width: 150px;
}

.head-content .sub_nav a.register {
  background: var(--color-secondary-dark);
  color: white;
  font-size: 18px;
  padding: 0 20px;
  margin-left: 0;
  width: 150px;
}
@media screen and (max-width: 650px) {
}

/*  メインコンテンツwrap   */
#main-wrapp {
  padding-top: 30px;
  margin-top: 30px;
  margin-bottom: 130px;
  background-color: #fff;
  /* overflow: hidden; */
}
@media screen and (max-width: 650px) {
  #main-wrapp {
    padding-top: 0;
  }
}

/* メインコンテンツ  */
#main {
  width: 1100px;
  float: left;
  padding-top: 30px;
}


@media screen and (max-width: 650px) {
  #main {
    width: 60%;
    float: auto;
    padding-top: 130px;
  }
}


/*  サイドコンテンツ  */
#side {
  width: 180px;
  min-height: 500px; /*--構築時カット--*/
  background-color: red;
  float: right;
}
#side .side-contents {
  margin-bottom: 20px;
}
@media screen and (max-width: 650px) {
  #side {
    width: 100%;
    float: auto;
    margin-top: 60px;
  }
  #main {
    width: 100%;
    float: auto;
  }
}

/* footer */
#footer {
  width: 100%;
  background: #b5defc;
  padding-top: 30px;
}
#footer .mywidth {
  position: relative;
}
#footer .mywidth .top_back {
  position: absolute;
  right: -100px;
  top: -145px;
}
#footer .foot_logo {
  text-align: left;
  margin-bottom: 25px;
}
#footer .foot-content {
  display: flex;
}
#footer .foot-content .address {
  min-width: 410px;
}
#footer .foot-content .address p {
  font-size: 15px;
  line-height: 2em;
  color: #000;
}
#footer .copy {
  background: #fff;
  color: #000;
  text-align: center;
  padding-top: 25px;
}
@media screen and (max-width: 650px) {
  #footer .foot-content {
    display: block;
  }
  #footer .foot_logo img {
    max-width: 200px;
  }
  #footer .foot-content .address {
    min-width: 0;
    margin-bottom: 30px;
  }
  #footer .foot-content .address p {
    text-align: center;
    font-size: 12px;
  }
  #footer .copy {
    font-size: 12px;
  }
  #footer .mywidth .top_back {
    right: 0;
    top: -120px;
  }
  #footer .mywidth .top_back img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
}

/*	パンくず */
.breadcrumbs {
  overflow: hidden;
  margin-bottom: 10px;
}
.breadcrumbs span,
.breadcrumbs a span {
  font-size: 12px;
}
.breadcrumbs {
  display: flex;
}
.breadcrumbs a span {
  color: #3baaff;
}
.breadcrumbs a span:hover {
  opacity: 0.7;
}
.breadcrumbs a span:after {
  content: '>';
  display: inline-block;
}

/*	ページャー  */
.pagination {
  display: flex;
  overflow: hidden;
  margin: 30px auto 0;
  justify-content: center;
}
.pagination li {
  display: inline-block;
  padding: 0 5px;
}
.pagination ul .page-numbers {
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #d2d2d2;
  display: block;
  font-size: 14px;
}
.pagination .page-numbers:hover {
  opacity: 0.6;
}
.pagination .current {
  background: #abaaaa !important;
  color: #fff;
}
@media screen and (max-width: 650px) {
  .pagination li {
    display: inline-block;
    padding: 0 2px;
    margin-bottom: 10px;
  }
  .pagination ul .page-numbers {
    padding: 5px 10px;
    background: #fff;
    border: 1px solid #d2d2d2;
    display: block;
    font-size: 13px;
  }
}

/* footer フローティングボタン・トップに戻る  */
#follow-fotter {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
}
.foot-button1 {
  border-radius: 10px;
  padding: 15px 5px;
  display: block;
  text-align: center;
}
#scroll-top a {
  border-radius: 4px;
  background: url('../images/scroll-top-top.png') no-repeat scroll 50% center
    rgba(34, 133, 191, 0.8);
  bottom: 70px;
  display: block;
  float: right;
  height: 35px;
  padding: 5px;
  position: fixed;
  right: 30px;
  width: 35px;
}
@media screen and (max-width: 650px) {
}

/*----------------------------------------------------
	基本汎用クラス
----------------------------------------------------*/
/* wrapp */
.content-box {
  margin-top: 30px;
  padding: 60px 30px;
  background: #eee;
}
@media screen and (max-width: 650px) {
  .content-box {
    padding: 30px 10px;
  }
}

/* title */
.content-title01 {
  font-size: 24px;
  font-weight: bold;
}
.content-sub-title01 {
  font-size: 20px;
}
@media screen and (max-width: 650px) {
  .content-title01 {
    font-size: 22px;
  }
  .content-sub-title01 {
    font-size: 18px;
  }
}

/* text */
.content-text01 {
  font-size: 14px;
}
.content-sub-text01 {
  font-size: 12px;
}
@media screen and (max-width: 650px) {
}

/* 装飾コメント */
.comment {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: inline-block;
  padding: 5px 15px;
  text-align: center;
  font-size: 12px;
}
@media screen and (max-width: 650px) {
}

/* dl */
.content-dl {
  padding: 10px 10px 20px;
}
.content-dl dt {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}
.content-dl dd {
  font-size: 14px;
}
@media screen and (max-width: 650px) {
  .content-dl {
    padding: 10px 10px 10px;
  }
  .content-dl dt {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .content-dl dd {
    font-size: 12px;
  }
}

/* ul */
.content-ul li {
  font-size: 14px;
  padding: 2px 0px;
  margin-left: 1em;
  text-indent: -1em;
}
@media screen and (max-width: 650px) {
}

/* table */
.content-table {
  border: 1px solid #999;
}
.content-table th,
.content-table td {
  border: 1px solid #999;
  padding: 10px 15px;
}
.content-table th {
  background: #258eef;
  width: 20%;
  color: #fff;
}
.content-table td {
  width: 80%;
  background: #fff;
}
@media screen and (max-width: 650px) {
  .content-table {
    display: block;
    border-top: 1px solid #999;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    border-bottom: none;
  }
  .content-table th,
  .content-table td {
    display: block;
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #999;
    padding: 10px 15px;
  }
}

/* box-shadow */
.b-shadow {
  box-shadow: 0px 0px 3px 2px #d6d7d7;
  -moz-box-shadow: 0px 0px 3px 2px #d6d7d7;
  -webkit-box-shadow: 0px 0px 3px 2px #d6d7d7;
}

/* text-shadow */
.t-shadow {
  text-shadow: #fff 1px 1px 0px, #fff -1px 1px 0px, #fff 1px -1px 0px,
    #fff -1px -1px 0px;
}

/* indent */
.indent {
  margin-left: 1em;
  text-indent: -1em;
}

/* border */
.border-1 {
  border: 1px solid #999;
}

/* color */
.white {
  color: #fff;
}
.red {
  color: red;
}
.white-back {
  background-color: #fff;
}
.green-back {
  background: #12e840;
}
.orange-back {
  background: #e88d12;
}
.main-color {
  /*--メイン文字カラー--*/
  color: #0097d2;
}
.main-back {
  /*--メイン背景カラー--*/
  background-color: #0097d2;
}
.sub-color {
  /*--サブ文字カラー--*/
  color: #fff;
}
.sub-back {
  /*--サブ背景カラー--*/
  background-color: #ebf6fb;
}
/*----------------------------------------------------
	リンク
----------------------------------------------------*/
.sp-link {
  pointer-events: none;
}
@media screen and (max-width: 650px) {
  .sp-link {
    pointer-events: auto;
  }
}

/*----------------------------------------
ポジション汎用
----------------------------------------*/
.relative {
  position: relative;
}

/*----------------------------------------
ディスプレイ汎用
----------------------------------------*/
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}

/*----------------------------------------
フロート汎用
----------------------------------------*/
.fl {
  float: left !important;
}
.fr {
  float: right !important;
}
.cf:after {
  clear: both;
}
.cf {
  overflow: hidden;
}

/*----------------------------------------
バーティカルアライン汎用
----------------------------------------*/
.va-t {
  vertical-align: top !important;
}
.va-m {
  vertical-align: middle !important;
}
.va-b {
  vertical-align: bottom !important;
}
.va-base {
  vertical-align: baseline !important;
}

/*----------------------------------------
幅汎用
----------------------------------------*/
.w30 {
  width: 30px;
}
.w50 {
  width: 50px;
}
/*----------------------------------------
パーセント汎用
----------------------------------------*/
.par5 {
  width: 5%;
}
.par10 {
  width: 10%;
}
.par15 {
  width: 15%;
}
.par20 {
  width: 20%;
}
.par25 {
  width: 25%;
}
.par30 {
  width: 30%;
}
.par33 {
  width: 33%;
}
.par35 {
  width: 35%;
}
.par40 {
  width: 40%;
}
.par45 {
  width: 45%;
}
.par50 {
  width: 50%;
}
.par55 {
  width: 55%;
}
.par60 {
  width: 60%;
}
.par65 {
  width: 65%;
}
.par70 {
  width: 70%;
}
.par75 {
  width: 75%;
}
.par80 {
  width: 80%;
}
.par85 {
  width: 85%;
}
.par90 {
  width: 90%;
}
.par95 {
  width: 95%;
}
.par100 {
  width: 100%;
}
@media screen and (max-width: 650px) {
  .sp-par100 {
    width: 100%;
  }
}
/*----------------------------------------
マージン汎用
----------------------------------------*/
.ma {
  margin: auto;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt25 {
  margin-top: 25px;
}
.mt30 {
  margin-top: 30px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb30 {
  margin-bottom: 30px;
}
@media screen and (max-width: 650px) {
  .sp-mt0 {
    margin-top: 0px;
  }
  .sp-mt10 {
    margin-top: 10px;
  }
  .sp-mt20 {
    margin-top: 20px;
  }
  .sp-mt30 {
    margin-top: 30px;
  }
}

/*----------------------------------------
パディング汎用
----------------------------------------*/
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.p25 {
  padding: 25px;
}
.p30 {
  padding: 30px;
}

/*----------------------------------------
フォントウェイト汎用
----------------------------------------*/
.tx-bold {
  font-weight: bold;
}
.tx-normal {
  font-weight: normal;
}

/*----------------------------------------
テキストアライン汎用
----------------------------------------*/
.tx-center {
  text-align: center;
}
.tx-left {
  text-align: left;
}
.tx-right {
  text-align: right;
}
@media screen and (max-width: 650px) {
  .sp-tx-center {
    text-align: center;
  }
  .sp-tx-left {
    text-align: left;
  }
  .sp-tx-right {
    text-align: right;
  }
}

/*----------------------------------------
ナビゲーションPC(ヘッダー)
----------------------------------------*/
#head-navi-pc {
  border-bottom: 4px solid #898785;
}
#head-navi-pc > .ul-wrap {
  display: grid;
  grid: auto-flow / 1fr min-content 1fr;
  justify-items: right;
  align-items: center;
}

#head-navi-pc > .ul-wrap > ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  grid-column-start: 2;
}
#head-navi-pc > .ul-wrap > ul > li {
  position: relative;
  vertical-align: middle;
  border-right: 1px dotted #fff;
  margin: 0 15px;
}
#head-navi-pc > .ul-wrap > ul > li > a {
  padding: 10px 0;
  text-decoration: none;
  display: block;
  font-size: 16px;
  white-space: nowrap;
}
#head-navi-pc > .ul-wrap > ul > li > a:hover {
  opacity: 0.5;
}
/*-------サブナビゲーションPC(ヘッダー)-----------*/

#head-navi-pc > .ul-wrap > ul > li > ul.sub-menu {
  opacity: 0;
  position: absolute;
}
#head-navi-pc > .ul-wrap > ul > li > ul.sub-menu:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-bottom: 5px solid #555;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin: auto;
}
#head-navi-pc > .ul-wrap > ul > li > ul.sub-menu > li {
  display: block;
  border-top: 1px dotted #ccc;
  background-color: #fff;
  text-align: left;
}
#head-navi-pc > .ul-wrap > ul > li > ul.sub-menu > li:first-child {
  border-top: 3px solid #555;
}
#head-navi-pc > .ul-wrap > ul > li > ul.sub-menu > li > a {
  display: block;
  padding: 10px;
}
#head-navi-pc > .ul-wrap > ul > li > ul.sub-menu > li > a:hover {
  opacity: 0.7;
}
/*----------------------------------------
ナビゲーションSP(ヘッダー)
----------------------------------------*/
header.sp-only {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  padding: 10px;
  z-index: 999;
  background: #fff;
}
header.sp-only ul {
  display: none;
  position: fixed;
  height: 100%;
  left: 0;
  right: 0;
  top: 60px;
  bottom: -500px;
  background: #fff;
  z-index: 2000;
}
header.sp-only ul li {
  list-style: none;
  
  border-top: 1px dotted #999;
}

header.sp-only ul li > div {
  margin: 20px;
}

header.sp-only ul li a {
  font-size: 13px;
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
}
header.sp-only ul .sp_sub_nav {
  display: flex;
}
header.sp-only ul .sp_sub_nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #eee;
  padding-left: 0;
  flex-basis: 0;
  flex-grow: 1;
}
header.sp-only ul .sp_sub_nav a:nth-child(2) {
  background: #3fa9f5;
  color: white;
  font-size: 12px;
}
header.sp-only ul .sp_sub_nav a:nth-child(1) {
  border-right: 1px dotted #999;
}
header.sp-only ul > li > a:hover {
  opacity: 0.5;
}
#head-navi-sp .navi-sp-content img {
  max-width: 200px;
}
#head-navi-sp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/*----------------------------------------
ナビゲーションPC(フッター)
----------------------------------------*/
#foot-navi-pc {
}
#foot-navi-pc > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 20px;
}
#foot-navi-pc > ul > li {
  min-width: 275px;
  margin-bottom: 25px;
}
#foot-navi-pc > ul > li:first-child {
}
#foot-navi-pc > ul > li > a {
}
#foot-navi-pc > ul > li > a:hover {
  opacity: 0.5;
}
/*----------------------------------------
ナビゲーションSP(フッター)
----------------------------------------*/
#foot-navi-sp {
  margin: 0 -10px;
}
#foot-navi-sp > .ul-wrap > ul > li > a {
  display: block;
  color: #555;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 32px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #afafaf;
  background: url(../images/top_111.png) no-repeat 95% center;
  text-shadow: 0px -1px 0px #fff !important;
  position: relative;
}
#foot-navi-sp > .ul-wrap > ul > li > a:after {
  content: '\f0da';
  font-family: 'FontAwesome';
  position: absolute;
  right: 20px;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
}
#foot-navi-sp > .ul-wrap > ul > li {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: #e8e8e8;
  background: -moz-linear-gradient(top, #e8e8e8 0%, #eeeeee 100%);
  background: -webkit-linear-gradient(top, #e8e8e8 0%, #eeeeee 100%);
  background: linear-gradient(to bottom, #e8e8e8 0%, #eeeeee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e8e8', endColorstr='#eeeeee', GradientType=0 );
}
#foot-navi-sp > .ul-wrap > ul > li > a:hover {
  opacity: 0.5;
}
/*----------------------------------------
ドロップダウンリスト
----------------------------------------*/
.navi-list ul {
  background: none repeat scroll 0 0 rgba(240, 240, 240, 0.9);
  display: none;
  list-style: none outside none;
  position: absolute;
  top: 56px;
  width: 200px;
  z-index: 80;
}
ul.global-navi li.navi-list li.drop-list {
  display: block !important;
  border: none !important;
  border-bottom: 1px dotted #ccc !important;
}
.navi-list ul a {
  height: auto !important;
}
/*----------------------------------------
スマホ出しわけ
----------------------------------------*/
/* .sp-only {
  display: none !important;
}
.pc-only {
  display: block;
} */
.pc-only.col {
  display: table-cell;
}
.pc-only.col-wrapp {
  display: table;
}
@media screen and (min-width: 651px) {
  .pc-only {
    display: block;
  }
  .sp-only {
    display: none !important;
  }
}

@media screen and (max-width: 650px) {
  .pc-only {
    display: none !important;
  }
  .sp-only {
    display: block;
  }
}

/*=============
flex-box汎用
=============*/
.flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
/*===コンテンツの配置方向 ===*/
.row {
  flex-direction: row; /*-- 左から --*/
}
.row-r {
  flex-direction: row-reverse; /*-- 右から --*/
}
.column {
  flex-direction: column; /*-- 上から --*/
}
.column-r {
  flex-direction: column-reverse; /*-- 下から --*/
}
/*===コンテンツの折返し===*/
.wrap {
  flex-wrap: wrap; /*-- 折返す --*/
}
.wrap-r {
  flex-wrap: wrap-reverse; /*-- 逆から折返す --*/
}
.no-wrap {
  flex-wrap: nowrap; /*-- 折返さない --*/
}
/*===コンテンツの水平方向揃え ===*/
.flex-left {
  justify-content: flex-start; /*-- 左寄せ --*/
}
.flex-right {
  justify-content: flex-end; /*-- 右寄せ --*/
}
.flex-center {
  justify-content: center; /*-- 中央寄せ --*/
}
.flex-space-b {
  justify-content: space-between; /*-- 均等にスペースを空け外寄せ --*/
}
.flex-space-a {
  justify-content: space-around; /*-- 均等にスペースを空け内寄せ --*/
}
/*===コンテンツの垂直方向揃え ===*/
.flex-top {
  align-items: flex-start; /*-- 上寄せ --*/
}
.flex-middle {
  align-items: center; /*-- 中央寄せ --*/
}
.flex-bottom {
  align-items: flex-end; /*-- 下寄せ --*/
}
/* 順番 */
.no1 {
  order: 1;
}
.no2 {
  order: 2;
}
.no3 {
  order: 3;
}
.no4 {
  order: 4;
}
.no5 {
  order: 5;
}
.no6 {
  order: 6;
}
.no7 {
  order: 7;
}
@media screen and (max-width: 650px) {
  .sp-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }
  .sp-row {
    flex-direction: row;
  }
  .sp-row-r {
    flex-direction: row-reverse;
  }
  .sp-column {
    flex-direction: column;
  }
  .sp-column-r {
    flex-direction: column-reverse;
  }
  /*===コンテンツの折返し===*/
  .sp-wrap {
    flex-wrap: wrap;
  }
  .sp-wrap-r {
    flex-wrap: wrap-reverse;
  }
  .sp-no-wrap {
    flex-wrap: nowrap;
  }
  /*===コンテンツの水平方向揃え ===*/
  .sp-flex-left {
    justify-content: flex-start;
  }
  .sp-flex-right {
    justify-content: flex-end;
  }
  .sp-flex-center {
    justify-content: center;
  }
  .sp-flex-space-b {
    justify-content: space-between;
  }
  .sp-flex-space-a {
    justify-content: space-around;
  }
  /*===コンテンツの垂直方向揃え ===*/
  .sp-flex-top {
    align-items: flex-start;
  }
  .sp-flex-middle {
    align-items: center;
  }
  .sp-flex-bottom {
    align-items: flex-end;
  }
  /* 順番 */
  .sp-no1 {
    order: 1;
  }
  .sp-no2 {
    order: 2;
  }
  .sp-no3 {
    order: 3;
  }
  .sp-no4 {
    order: 4;
  }
  .sp-no5 {
    order: 5;
  }
  .sp-no6 {
    order: 6;
  }
  .sp-no7 {
    order: 7;
  }
} /*-- sp end --*/
/*-- flex end--*/
